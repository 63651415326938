/**
 * @created by Plamena Gancheva on 08/11/2021
 * @last-updated by Zheko Hristov on 12/11/2021
 *
 * Locale scripts
 **/

var locale = (function ($) {
    'use strict';
    function init() {}

    function getLocale() {
        let locale = $('#site_locale').data('locale') || 'en-US';

        return locale;
    }

    return {
        init: init,
        getLocale: getLocale,
    };
})(jQuery);

export default locale;
