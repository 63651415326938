/**
 * @created by Plamena Gancheva on 08/11/2021
 * @last-updated by Zheko Hristov on 12/11/2021
 *
 * Cookies scripts
 **/
//import 'vanilla-cookieconsent/dist/cookieconsent';

import * as CookieConsent from "vanilla-cookieconsent";

let cookies = (function ($) {
    function init() {
        askCookiesPermission();

        // this.boundClearCookieConsent = this.clearCookieConsent.bind(this);
        // document.addEventListener("turbolinks:before-render", this.boundClearCookieConsent)
    }

    function clearCookieConsent() {
        // if ($('#cc--main').length > 0) {
        //     $('#cc--main').remove();
        //     $('html').removeClass('show--consent force--consent show--settings');
        //     return;
        // }

        CookieConsent.reset();
    }

    function askCookiesPermission() {
      let siteLocale = $('#site_locale').data('locale') || 'en';
      let cookieconsentLocale = 'en';
      if(siteLocale == 'bg-BG'){
        cookieconsentLocale = 'bg';
      }
     // CookieConsent.setLanguage(locale);
        
      CookieConsent.run({
        // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
        guiOptions: {
          consentModal: {
            layout: 'cloud',
            position: 'middle center',
            equalWeightButtons: false,
            flipButtons: false
          },
          preferencesModal: {
            layout: 'box',
            equalWeightButtons: false,
            flipButtons: false
          }
        },

        onFirstConsent: function(cookie) {
          // callback triggered only once on the first accept/reject action
          window.dataLayer.push({ 'event': 'client-consent-update' });
        },

        categories: {
          necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
          },
          analytics: {
            autoClear: {
              cookies: [
                {
                  name: /^_ga/,   // regex: match all cookies starting with '_ga'
                },
                {
                  name: '_gid',   // string: exact cookie name
                }
              ]
            },

            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
            services: {
              ga: {
                label: 'Google Analytics',
                cookies: [
                  {
                    name: /^(_ga|_gid)/
                  }
                ]
              }
            }
          },
          targeting: {}
        },

        language: {
          default: cookieconsentLocale,
          translations: {
            en: {
              consentModal: {
                title: 'We use cookies',
                description: 'We use cookies to personalize and improve your site experience. By selecting the "Accept All" button, we can provide you with a better experience. Blocking certain types of cookies will affect how we deliver personalized content to you.',
                acceptAllBtn: 'Accept all',
                acceptNecessaryBtn: 'Reject all',
                showPreferencesBtn: 'Manage Individual preferences'
              },
              preferencesModal: {
                title: 'Manage cookie preferences',
                acceptAllBtn: 'Accept all',
                acceptNecessaryBtn: 'Reject all',
                savePreferencesBtn: 'Accept current selection',
                closeIconLabel: 'Close modal',
                sections: [
                  {
                    title: "Cookie usage",
                    description: "We use cookies to ensure the basic functionalities of the website and to enhance your online experience ..."
                  },
                  {
                    title: "Strictly necessary cookies",
                    description: "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
                    linkedCategory: "necessary"
                  },
                  {
                    title: "Performance and Analytics cookies",
                    description: "These cookies allow the website to remember the choices you have made in the past",
                    linkedCategory: "analytics",
                    cookieTable: {
                        headers: {
                          name: "Name",
                          domain: "Service",
                          description: "Description",
                          expiration: "Expiration"
                        },
                        body: [
                          {
                            name: "_ga",
                            domain: "Google Analytics",
                            description: "Cookie set by <a href=\"#das\">Google Analytics</a>",
                            expiration: "Expires after 12 days"
                          },
                          {
                            name: "_gid",
                            domain: "Google Analytics",
                            description: "Cookie set by <a href=\"#das\">Google Analytics</a>",
                            expiration: "Session"
                          }
                        ]
                      }
                    },
                    {
                        title: 'Targeting and Advertising',
                        description: 'These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                        linkedCategory: 'targeting',
                    },
                    {
                      title: 'More information',
                      description: 'For any queries in relation to my policy on cookies and your choices, please <a href="/en-US/Contacts">contact us</a>'
                    }
                ]
              }
            },
            bg: {
              consentModal: {
                title: 'Ние използваме бисквитки',
                description: 'Ние използваме бисквитки, за да персонализираме и подобрим вашето изживяване на сайта. Избирайки бутона „Приемам всички“, можем да ви предоставим по-добро изживяване. Блокирането на определени видове бисквитки ще повлияе на начина, по който ви доставяме персонализирано съдържание.',
                acceptAllBtn: 'Приеми всички',
                acceptNecessaryBtn: 'Откажи всички',
                showPreferencesBtn: 'Опции за управление'
              },
              preferencesModal: {
                title: 'Предпочитания за бисквитки',
                acceptAllBtn: 'Приеми всички',
                acceptNecessaryBtn: 'Приеми всички',
                savePreferencesBtn: 'Запази избраните',
                closeIconLabel: 'Затвори',
                sections: [
                  {
                    title: "Бисквитки",
                    description: "Ние използваме бисквитки, за да гарантираме основните функции на уебсайта и да подобрим вашето онлайн изживяване."
                  },
                  {
                    title: "Строго необходими бисквитки",
                    description: "Тези бисквитки са от съществено значение за правилното функциониране на този уебсайт. Без тези бисквитки уебсайтът няма да работи правилно.",
                    linkedCategory: "necessary"
                  },
                  {
                    title: "Анализ",
                    description: "These cookies allow the website to remember the choices you have made in the past",
                    linkedCategory: "analytics",
                    cookieTable: {
                        headers: {
                          name: "Name",
                          domain: "Service",
                          description: "Description",
                          expiration: "Expiration"
                        },
                        body: [
                          {
                            name: "_ga",
                            domain: "Google Analytics",
                            description: "Cookie set by <a href=\"#das\">Google Analytics</a>",
                            expiration: "Expires after 12 days"
                          },
                          {
                            name: "_gid",
                            domain: "Google Analytics",
                            description: "Cookie set by <a href=\"#das\">Google Analytics</a>",
                            expiration: "Session"
                          }
                        ]
                      }
                    },
                    {
                        title: 'Таргетиране и реклама',
                        description: 'These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                        linkedCategory: 'targeting',
                    },
                    {
                      title: 'Повече информация',
                      description: 'За всякакви въпроси във връзка с моята политика относно бисквитките и вашия избор, моля, <a href="/en-US/Contacts">свържете се с нас</a>'
                    }
                ]
              }
            }
          }
        }
      });

     //   CookieConsent.show(true);
    }

    return {
        init: init,
        clear: clearCookieConsent
    };
})(jQuery);

export default cookies;
